<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="allDailySadaqahRecurringDonations && allDailySadaqahRecurringDonations.length > 0">
      <!-- TABLE HEADING -->
      <div class="row m-0">
        <div class="col-12 px-0">
          <!--DESKTOP-->
          <div class="row font13 green-text-dark d-none d-md-flex">
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                REFERENCE
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                NAME/DESCRIPTION
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                PAYMENT METHOD
              </div>
            </div>
            <div class="col-md bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                START DATE
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                DAILY AMOUNT
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                TOOLS
              </div>
            </div>
          </div>
          <!--END DESKTOP-->
          <!--MOBILE-->
          <div class="row font13 green-text-dark d-md-none">
            <div class="col-3 bold text-center px-0">
              Ref.
            </div>
            <div class="col-3 bold text-center px-0">
              Start Date
            </div>
            <div class="col-3 bold text-center px-0">
              Daily Amount
            </div>
            <div class="col-3 bold text-center px-0">
              Info
            </div>
          </div>
          <!--END MOBILE-->
        </div>
      </div>
      <!-- END TABLE HEADING -->
      <ManageSadaqahItem v-for="(donation, key) in allDailySadaqahRecurringDonations.slice(start, finish)" :key="key" :item="donation" :thekey="key" @showDetails="showDetails(key)" @print="print(donation.debitOrderID)" @deleteOrder="deleteOrder(donation.debitOrderID)" />
      <transition name="slideup">
      <ManageSadaqahPopup v-if="currentItem" :item="currentItem" @close="closeDetails" />
      </transition>
      <!-- PAGINATION -->
      <div class="row mx-0 mt-5" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
    </div>
    <div class="col-12 p-0 text-center" v-else>
      You don't have any Daily Sadaqah Donations, set some up.
    </div>
    <transition name="fade">
      <Popup @close="close" v-if="isDeleteDebit">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Cancel Debit Order {{currentID}}
          </div>
          <div class="col-12 text-center mb-3">
            Are you sure you would like to cancel this debit order?
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="grey" btnText="Keep Debit Order" width="100" @buttonClicked="isDeleteDebit = false" />
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="red" btnText="Cancel Debit Order" width="100" @buttonClicked="reallyDelete" />
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    ManageSadaqahItem: defineAsyncComponent(() => import('./ManageSadaqahItem.vue')),
    ManageSadaqahPopup: defineAsyncComponent(() => import('./ManageSadaqahPopup.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue'))
  },
  name: 'ManageSadaqahTable',
  data () {
    return {
      projectType: null,
      startDate: null,
      endDate: null,
      priceRange: null,
      donationType: null,
      itemsPerPage: 10,
      currentPage: 0,
      currentItem: null,
      currentKey: null,
      isDeleteDebit: false,
      currentID: null
    }
  },
  async mounted () {
    await this.getTransactions(0, 20)
  },
  computed: {
    ...mapGetters(['user', 'allDailySadaqahRecurringDonations', 'allDailySadaqahRecurringDonationsStatus', 'countDailySadaqahDonations']),
    totalPages () {
      return Math.ceil(this.countDailySadaqahDonations / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  },
  methods: {
    ...mapActions(['getAllDailySadaqahRecurringDonations']),
    async getTransactions (startPosition, pageSize) {
      const options = {
        startPosition,
        pageSize
      }
      await this.getAllDailySadaqahRecurringDonations(options)
    },
    goToPage (val) {
      if (val > this.currentPage) {
        const start = (this.currentPage * 10) + 10
        const pageSize = ((val - this.currentPage) * 10)
        this.getTransactions(start, pageSize)
      }
      this.$emit('goToDonations')
    },
    showDetails (val) {
      this.currentKey = this.start + val
      this.currentItem = this.allDailySadaqahRecurringDonations[this.currentKey]
    },
    deleteOrder (val) {
      this.currentID = val
      this.isDeleteDebit = true
    },
    async reallyDelete () {
      await this.stopDebitOrder(this.currentID)
    },
    async print (val) {
      await this.printTransactionReceipt(val)
    },
    close () {
      this.isDeleteDebit = false
      this.currentID = null
    },
    closeDetails () {
      this.currentItem = null
      this.currentKey = null
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
